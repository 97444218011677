import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { ProfileService } from 'src/app/core/services/profile.service';
// import { ProfileService } from 'src/app/core/services/profile.service';
// import { TreeService } from 'src/app/core/services/tree.service';
declare var $: any
@Component({
  selector: 'app-select-from-tree',
  templateUrl: './select-from-tree.component.html',
  styleUrls: ['./select-from-tree.component.scss']
})
export class SelectFromTreeComponent {
  mainCategories = [];
  allChoosesLemitError = false;
  searchTerm = ''
  @Input() searchTermFromChild = ''
  cateogries: any = []
  allChooses: any = []
  selected: any = [];
  categoryId = [];
  routeCheck = false;
  @Input() id: any;
  @Input() dataSelected: any = []
  @Output() check = new EventEmitter<any>();
  constructor(
    public cd:ChangeDetectorRef,
    public _categories: CategoriesService,
    // public _tree: TreeService,
    private profile: ProfileService,
    public router: Router,
    public _auth: AuthService,
    public activeRouter: Router) {   
    document.body.addEventListener("click", (e: any) => {
      if (e.target.classList[0] !== "inside" && e.target.localName !== 'input'){
        this.hideListOfCategories();
      }
    })
    
  }

  

  ngAfterViewInit(){
    let user = JSON.parse(localStorage.getItem('user')!);
    if(this.router.url.includes("/ads/add-new-advrties") || this.router.url.includes(`/profile/${this.router.url.split('/')[2]}/Occupy-me`)
    ||this.router.url.includes("edit-ads"))
      this.routeCheck = true
    if (this.router.url.includes("/ads/add-new-advrties")||this.router.url.includes("edit-ads")) {
      this.profile.getUserData(user?.data?.id).subscribe((res: any) => {
        let addCategory = []
        this.cateogries = res?.data?.category
        res.data.category.map((el:any , index:number) => {
          if(index !< 10) {
            addCategory.push(el)
          }
        })
        this.selected = addCategory
        this.allChooses = addCategory
        console.log(this.allChooses);
        this.check.emit(this.selected);
        this.check.emit(this.allChooses);
        if (this.allChooses.length > 10 || this.selected.length > 10) this.allChoosesLemitError = true;

      })
    } else if (this.router.url.includes(`/profile/${this.activeRouter.url.split('/')[2]}/Occupy-me`)) {
      this.id=this.activeRouter.url.split('/')[2]
      this.profile.getUserData(this.activeRouter.url.split('/')[2]).subscribe((res: any) => {
        let addCategory = []
        this.cateogries = res?.data?.category
        res.data.category.map((el:any , index:number) => {
          if(index !< 10) {
            addCategory.push(el)
          }
        })
        this.selected = addCategory
        this.allChooses = addCategory
        this.check.emit(this.selected);
        this.check.emit(this.allChooses);
        if (this.allChooses.length > 10 || this.selected.length > 10) this.allChoosesLemitError = true;

      })
    } 
    // this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.router.url.includes(`/profile/${this.id}/Occupy-me`) && !this.router.url.includes("/ads/add-new-advrties")
    && !this.router.url.includes("edit-ads")){
      this.searchForCategories(this.searchTermFromChild, true);
    }

    if(this.dataSelected?.length>0){
      let inp:any;
      this.allChooses=[...this.dataSelected]
      this.check.emit(this.allChooses);
      // this.allChooses?.filter((ele: any, index: any, arr: any) => {
      //     inp=document.getElementById('19');
      //     console.log('ele' , inp);
      //     inp.checked=true
      // });
    }
    // if(changes["searchTermFromChild"])
  }

  openCategory(event: any, listOfChildrens?: any) {
    $("#" + event.target.id).toggleClass("fa-chevron-left fa-chevron-down", 1000)
    $("#" + event.target.id + "Child").slideToggle();
    // this.checkFinalChildrens(event, listOfChildrens)

  }

  checkFinalChildrens(event: any, childrens: any) {
    if (childrens && childrens.length > 0) {
      let i = 0;
      for (let index = 0; index < childrens.length; index++) {
        if (childrens[index]?.children.length == 0) i++;
      }
      if (i == childrens.length) {
        if (event.target.checked) {
          $(`${"#" + event.target.classList[0]?.slice(0, event.target.classList.length - 8)}`).addClass("finalChild");
          for (let input = 0; input < $(".finalChild input").length; input++) {
            $(".finalChild input")[input].checked = true;
            this.allChooses.push({ id: childrens[input].id, name: childrens[input].name });
            this.check.emit(this.allChooses);
          }
        }
        else {
          for (let x = 0; x < $(".finalChild input").length; x++) {
            $(".finalChild input")[x].checked = false;
            this.allChooses?.filter((ele: any, index: any, arr: any) => {
              if (arr[index].id == childrens[x].id) {
                this.removeCategory(index, childrens[x].id);
              }
            });
          }
          $(`${"#" + event.target.classList[0]?.slice(0, event.target.classList.length - 8)}`).removeClass("finalChild");
        }
      }
    }
  }

  selectItem(event: any, item: any) {
    this.checkFinalChildrens(event, item.children)
    this.allChooses.length >= 10 ? this.allChoosesLemitError = true : this.allChoosesLemitError = false
    if (event.target.checked && this.allChooses.filter((ele: any) => ele.id == item.id).length == 0 && this.allChooses.length < 10) {
      this.allChooses.push({ id: item.id, name: item.name });
      this.check.emit(this.allChooses)
    }
    else if (!event.target.checked) {
      for (let i = 0; i < this.allChooses.length; i++) {
        if (this.allChooses[i].id == item.id) this.removeCategory(i)
      }
    }
  }

  selectItemAny(item: any) {
    if (this.allChooses.filter((ele: any) => ele.id == 632).length == 0) {
      this.allChooses.push(item);
      this.check.emit(this.allChooses)
    }
  }

  showListOfCategories() {
    $('.ng-dropdown-panel').addClass("d-block").removeClass("d-none")
  }

  hideListOfCategories() {
    $('.ng-dropdown-panel').removeClass("d-block").addClass("d-none")
  }

  removeCategory(index: any, id?: any) {
    this.allChooses.splice(index, 1);
    this.allChooses.length >= 10 ? this.allChoosesLemitError = true : this.allChoosesLemitError = false;
    // this.hideListOfCategories();
    let ele: any = document.getElementById(id)
    if (id) ele.checked = false;
    this.check.emit(this.allChooses);
  }

  searchForCategories(searchTerm: any, isAppended?: boolean) {
    if (searchTerm.length == 3 && (!this.router.url.includes("Add-new-request")||!this.router.url.includes("edit-request") 
    ||!this.router.url.includes("edit-ads"))) {
      this._categories.getCategories().subscribe((res: any) => {
        this.cateogries = res.data
      })
    }else{
      this.mainCategories = [];
      let searchResult = [];
      this._categories.searchCategories(searchTerm).subscribe((res: any) => {
        if (isAppended == true) {
          this._categories.getCategories().subscribe((resonse: any) => {
            this.mainCategories = resonse.data;
            if (res.data?.length > 0) {
              searchResult = res.data;
              this.cateogries = [...searchResult, ...this.mainCategories];
            }
            else {
              this.cateogries = this.mainCategories;
            }
          })
        }
        else {
          this.cateogries = res.data;
        }
      })
    }

  }

}
