import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  notification = new BehaviorSubject<any>([]);
  readNotification: Observable<any> ;
  profileData:any
  Avatar = new BehaviorSubject<string>(null);
  searchValue = new BehaviorSubject<string>(null);

  constructor(private _http:HttpClient ) {
  }
  getUserData(id: any) {
    return this._http.get(`${environment.apiUrl}/api/user/profile?id=${id}`)
  }
  updateUserData(id: any , data:any) {
    return this._http.post(`${environment.apiUrl}/api/user/update/${id}`, data)
  }
  // get all lang
  getAllLang() {
    return this._http.get(`${environment.apiUrl}/api/coredata/language/list`)
  }
  // get all social
  getAllSocial() {
    return this._http.get(`${environment.apiUrl}/api/coredata/social/list`)
  }
  // get Lang And Social
  getLangAndSocial(id:string) {
    return this._http.get(`${environment.apiUrl}/api/coredata/${id}/list`)
  }
  // get my ads
  getMyAds(){
    return this._http.get(`${environment.apiUrl}/api/ad/my_list`)
  }
  // get my general Request
  getMyRequest(){
    return this._http.get(`${environment.apiUrl}/api/task/my_list?pagination=true`)
  }
  // get my special Request
  getMyRequestSpecial(){
    return this._http.get(`${environment.apiUrl}/api/task/my_list_special`)
  }
  // get my Request offer
  getMyRequestOffer(){
    return this._http.get(`${environment.apiUrl}/api/task/my_offer`)
  }
  // search in request
  searchInMyRequestOffer(value:any){
    this._http.get(`${environment.apiUrl}/api/task/searchName?searchName=${value}`).subscribe((res:any) => {
      this.searchValue.next(res)
    })
  }
  // get my Notification
  getMyNotification(){
    return this._http.get(`${environment.apiUrl}/api/setting/notification/list`)
  }
  // get Five Of Notification
  getFiveNotification(){
    return this._http.get(`${environment.apiUrl}/api/setting/notification/list?perPage=5&pagination=true`)
  }
  readMyNotification(){
    let not = new Observable <any>()
    not = this._http.get(`${environment.apiUrl}/api/setting/notification/list?pagination=true`)
    not.subscribe((payload: any) => {
      this.notification.next(payload)
    })
  }
  getList(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}/api/setting/notification/list`);
  }
  // red my Request
  getRedNotification(id:number){
    return this._http.get(`${environment.apiUrl}/api/setting/notification/read/${id}`)
  }
  // get my Request offer
  getMyFavorite(model:any){
    return this._http.get(`${environment.apiUrl}/api/favourite/list?model=${model}`)
  }
  // get my Request offer
  getMyAssessments(id:any){
    return this._http.get(`${environment.apiUrl}/api/user/reviews?id=${id}`)
  }

}
