<div
  class="border-f-radius-s12 mb-3 box-shadow p-4 bg-white d-flex~"
  *ngFor="let data of data; let i = index"
>
  <div class="d-lg-flex w-100">
    <div class="d-flex">
      <!-- user image -->
      <div
        class="position-relative d-flex justify-content-center align-items-center userImg-s"
        [routerLink]="[_AuthService.id !== data?.id ? '/profile/FreelancerProfile/View/' + data?.id : '/profile/FreelancerProfile/my-profile']"
      >
        <img
          loading="lazy"
          alt="profile-PIC"
          [src]="data.avatar"
          class="w-100 h-100 border-f-radius-s12 relative cursor-click"
        />
      </div>
      <div
        class="me-2 ms-0"
        *ngIf="this._BreakPointService.isMobile === true"
        style="width: calc(100% - 80px)"
      >
        <div class="d-flex align-items-center justify-content-between">
          <p
            class="m-0 font-Bold-s18 d-flex align-items-center text-dark-black cursor-click"
            [routerLink]="[_AuthService.id !== data?.id ? '/profile/FreelancerProfile/View/' + data?.id : '/profile/FreelancerProfile/my-profile']"
          >
            {{ data.fullname }}
            <span
              ><img
                *ngIf="data.nationality != null"
                loading="lazy"
                alt="nationality-logo"
                class="iC-S20 ms-1.5"
                src="{{ data.nationality?.logo }}"
                alt=""
            /></span>
          </p>
          <!-- Favourite -->
          <div class="Border-grey border-f-radius-s12 p-2.5 cursor-click">
            <i
              [ngClass]="
                data.is_favourite == 0
                  ? 'ic-all-request-save'
                  : 'ic-request-favorite'
              "
              class="cursor-click d-flex img-Background-Cover iC-S16X20"
              (click)="addOrRemoveRequestToFavorite(data.id, i, $event)"
            ></i>
          </div>
        </div>
        <p class="m-0 font-Regular-s14 text-light-black-color cursor-click">
          {{ data.job_name_id?.name }}
          <span *ngIf="data.job_name_id == null">غير معرف</span>
        </p>
      </div>
    </div>
    <!-- name + nationality Logo -->
    <div
      class="me-3 ms-0"
      style="width: calc(100% - 178px)"
      [ngClass]="{ 'w-100': this._BreakPointService.isMobile }"
    >
      <div *ngIf="this._BreakPointService.isMobile === false">
        <div class="d-flex align-items-center justify-content-between">
          <p
            class="m-0 font-Bold-s18 d-flex align-items-center text-dark-black cursor-click"
            [routerLink]="[_AuthService.id !== data?.id ? '/profile/FreelancerProfile/View/' + data?.id : '/profile/FreelancerProfile/my-profile']"
          >
            {{ data.fullname }}
            <span
              ><img
                *ngIf="data.nationality != null"
                loading="lazy"
                alt="nationality-logo"
                class="iC-S20 ms-1.5"
                src="{{ data.nationality?.logo }}"
                alt=""
            /></span>
          </p>
          <!-- Favourite -->
          <div class="Border-grey border-f-radius-s12 p-2.5 cursor-click">
            <i
              [ngClass]="
                data.is_favourite == 0
                  ? 'ic-all-request-save'
                  : 'ic-request-favorite'
              "
              class="cursor-click d-flex img-Background-Cover iC-S16X20"
              (click)="addOrRemoveRequestToFavorite(data.id, i, $event)"
            ></i>
          </div>
        </div>
        <p class="m-0 font-Regular-s14 text-light-black-color cursor-click">
          {{ data.job_name_id?.name }}
          <span *ngIf="data.job_name_id == null">غير معرف</span>
        </p>
      </div>
      <div class="">
        <p
          *ngIf="data.info !== 'undefined'"
          class="m-0 mt-2 font-Regular-s16 text-support2-color break-words flex-wrap"
          (click)="seeMore = true; this.numberOfSee = 180"
        >
          {{ data.info?.slice(0, numberOfSee) }}
        </p>
        <span
          (click)="seeMore = false; this.numberOfSee = data.info?.length"
          *ngIf="data.info?.length > numberOfSee && seeMore"
          class="cursor-click"
          >...</span
        >
      </div>
      <!-- category of card -->
      <div class="d-flex mt-3 justify-between">
        <div
          *ngFor="let items of data?.category?.slice(0, 3)"
          class="m-0 text-nowrap ms-2 bg-light-white text-support2-color font-Regular-s12 px-3 py-1 border-f-radius-s10"
        >
          <span>{{ items.name }}</span>
          <!-- <span *ngIf="data.category.children[0].length !> 3" class="m-0  ms-2 bg-light-white font-Regular-s12 px-3 py-1 border-f-radius-s10 cursor-click">+4</span> -->
        </div>
        <div *ngIf="data.category.length == 1" class="d-flex">
          <p
            *ngFor="let children of data.category[0]?.children?.slice(0, 4)"
            class="m-0 text-nowrap ms-2 bg-light-white text-support2-color d-flex font-Regular-s12 px-3 py-1 border-f-radius-s10"
          >
            <span>{{ children.name }}</span>
          </p>
          <span
            *ngIf="data.category[0]?.children?.length >= 4"
            class="m-0 ms-2 bg-light-white font-Regular-s12 px-3 py-1 border-f-radius-s10 cursor-click"
            >+4</span
          >
        </div>
        <div *ngIf="data.category.length == 2" class="d-flex">
          <p
            *ngFor="let children of data.category[0]?.children?.slice(0, 1)"
            class="m-0 ms-2 bg-light-white text-support2-color d-flex font-Regular-s12 px-3 py-1 border-f-radius-s10"
          >
            <span>{{ children.name }}</span>
          </p>
          <span
            *ngIf="data.category[0]?.children?.length >= 3"
            class="m-0 ms-2 bg-light-white font-Regular-s12 px-3 py-1 border-f-radius-s10 cursor-click"
            >+4</span
          >
        </div>
        <span
          *ngIf="data.category?.length > 3"
          class="m-0 ms-2 bg-light-white font-Regular-s12 px-3 py-1 border-f-radius-s10 cursor-click"
          >+4</span
        >
        <button
      class="py-2 px-4 font-Bold-s16 d-flex align-items-center justify-content-center border-f-radius-s12 Border-light-grey bg-main-gredient text-white"
      id="contact"
      aria-label="contact"
    >
      <i
        class="ic-request-details-Paper-Plane iC-S20 img-Background-Contain d-flex me-2"
      ></i>
      تواصل معي
    </button>
      </div>
    </div>
  </div>
  <div *ngIf="this._BreakPointService.isMobile" class="mt-3">
    <div
      [ngClass]="{
        'mt-3 pt-3 w-100 Border-T-grey': this._BreakPointService.isMobile
      }"
    >
      <button
        class="py-2 px-4 font-Bold-s16 d-flex align-items-center justify-content-center border-f-radius-s12 Border-light-grey bg-main-gredient text-white"
        id="contact"
        aria-label="contact"
        [ngClass]="{ 'w-100': this._BreakPointService.isMobile }"
      >
        <i
          class="ic-request-details-Paper-Plane iC-S20 img-Background-Contain d-flex me-2"
        ></i>
        تواصل معي
      </button>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center w-100 mt-2" *ngIf="number < data?.length">
    <button class="border-0 border-f-radius-s8 font-Bold-s16 text-white-color bg-main-gredient px-5 py-1 line-height-2 "
    (click)="number = data?.length"
    >إعرض المزيد</button>
  </div>