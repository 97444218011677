<div class="table_center d-flex justify-content-center">
  <div class="dropdown drop-down">
    <a class="drop-down__button cursor-click" href="#" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <span class="drop-down__name d-flex align-items-center gap-2">
        <!-- user image -->
        <div
          class="border-f-radius-s12 iC-S48 p-4 bg-light-blue-1 position-relative">
          <div
            class="border-f-radius-s12 iC-S40 img-Background-Contain d-flex image-holder"
            style="z-index: 5;background-image: url({{userAvatar}})"></div>
          <!-- <i class="ic-User-Placeholder-icon border-f-radius-s12 iC-S40 img-Background-Contain d-flex image-holder"
                style="z-index: 5;"></i> -->
        </div>
        <!-- fullname -->
        <!-- <div class="d-flex flex-column">
              <p class="m-0 font-Regular-s14 text-dark-black">{{_AuthService.fullname}}</p>
              <p class="m-0 font-Regular-s12 text-light-black">{{_AuthService.userRole}}</p>
            </div> -->
        <!-- Arrow-Down -->
        <i
          class="ic-Navbar-Arrow-Down-icon iC-S10 img-Background-Contain d-flex me-2"></i>
      </span>
    </a>

    <ul class="drop-down__menu-box p-1 dropdown-menu">
      <div
        class="img-Background-Cover border-f-radius-s12 d-flex header-holder position-relative"
        style="background-image: url({{this.userData?.cover}})">
        <div
          class="border-f-radius-s12 iC-S100 img-Background-Cover d-flex user-image-holder position-absolute"
          style="z-index: 5; background-image: url({{userAvatar}})"></div>
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center gap-1 mt-5 px-3">
        <div class="d-flex align-items-center gap-2">
          <p class="m-0 font-Bold-s18 text-dark-black">{{_AuthService.userFullName}}</p>
          <img appHideImageOnError
            src="{{userData?.nationality?.logo}}"
            class="iC-S20 img-Background-Contain d-flex" loading="lazy"
            alt="nationality logo">
        </div>
        <p class="m-0 font-Regular-s14 text-support2-color">{{userData?.job_name?.name}}</p>
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-3">
          <div class="d-flex align-items-center gap-1">
            <i class="ic-User-icon iC-S16 img-Background-Contain d-flex"></i>
            <p class="m-0 text-support2-color font-Regular-s14">{{userData?.role?.name | translate }}</p>
          </div>
          <div class="d-flex align-items-center gap-3">
            <p class="m-0 text-light-black font-Regular-s14">الحاله</p>
            <mat-slide-toggle id="toggle" (click)="switch()" class="available"
              name="available" [(ngModel)]="available"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="horizontal-line mx-3 my-3"></div>
      <ul class="drop-down__menu pt-2">
        <!-- <li data-name="profile" class="drop-down__item"
          (click)="this._AuthService.closeDropdown()"
          routerLink="/ads/add-new-advrties"
          *ngIf="this.userData?.role?.id != 2">
          <i class="ic-Add-icon iC-S20 img-Background-Contain d-flex"></i>
          <span class="text-support2-color font-Bold-s14">أضف إعلان</span>
        </li> -->
        <li data-name="dashboard" class="drop-down__item"
          (click)="this._AuthService.closeDropdown() ; changeProfile(_AuthService.id)">
          <i class="ic-User-icon iC-S20 img-Background-Contain d-flex"></i>
          <span class="text-support2-color font-Bold-s14">الملف الشخصي</span>
        </li>
        <li data-name="dashboard" class="drop-down__item"
          (click)="this._AuthService.closeDropdown()"
          routerLink="/settings">
          <i class="ic-settings iC-S20 img-Background-Contain d-flex"></i>
          <span class="text-support2-color font-Bold-s14">الإعدادات</span>
        </li>
        <li data-name="activity" class="drop-down__item"
          (click)="this._AuthService.logout();this._AuthService.closeDropdown()">
          <i class="ic-Signout-icon iC-S20 img-Background-Contain d-flex"></i>
          <span class="text-support2-color font-Bold-s14">تسجيل خروج</span>
        </li>
      </ul>
    </ul>
  </div>
</div>
