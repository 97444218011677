import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-ads-card',
  templateUrl: './ads-card.component.html',
  styleUrls: ['./ads-card.component.scss']
})
export class AdsCardComponent {
  @Input() data:any = []
  @Output() Favorite = new EventEmitter()
  number:number = 5

constructor(public activeRouter: Router , public auth : AuthService) {}
ngOnChanges(){
}
addOrRemoveRequestToFavorite(id: any, i: number ,event:any) {
this.Favorite.emit({id:id , i:i , event:event});
}

}
