import { Directive, Input, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { SharedComponent } from './shared.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ButtonComponent } from './components/button/button.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WarningSnackBarComponent } from './components/warning-snack-bar/warning-snack-bar.component';
import { SuccessSnackBarComponent } from './components/success-snack-bar/success-snack-bar.component';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileIconComponent } from './components/user-profile-icon/user-profile-icon.component';
import { TreeComponent } from './components/tree/tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './components/header/header.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StarRatingModule } from 'angular-star-rating';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTooltipModule } from '@angular/material/tooltip';
// import { FiltrationComponent } from './components/filtration/filtration.component';
import { ActionsPopupComponent } from './components/actions-popup/actions-popup.component';
import { RequestOfferPopupComponent } from './components/request-offer-popup/request-offer-popup.component';
import { RequestStatusComponent } from './components/request-status/request-status.component';
import { FreelancerOfferComponent } from './components/freelancer-offer/freelancer-offer.component';
import { EditOfferComponent } from './components/edit-offer/edit-offer.component';
import { ChatComponent } from './components/chat/chat.component';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { LoaderProgressComponent } from './components/loader-progress/loader-progress.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PlaceHolderComponent } from './components/place-holder/place-holder.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ForgetPasswordMobileComponent } from './components/forget-password/pages/forget-password-mobile/forget-password-mobile.component';
import { ForgetPasswordOtpComponent } from './components/forget-password/pages/forget-password-otp/forget-password-otp.component';
import { ForgetPasswordNewPasswordComponent } from './components/forget-password/pages/forget-password-new-password/forget-password-new-password.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgOtpInputModule } from 'ng-otp-input';
import { NotificationSnackBarComponent } from './components/notification-snack-bar/notification-snack-bar.component';
import { ChatDatePipe } from '../core/pipes/chat-date.pipe';
import { RequestFilterationComponent } from './components/request-filteration/request-filteration.component';
import { MapComponent } from './components/map/map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MobileInputComponent } from './components/mobile-input/mobile-input.component';
import { SelectFromTreeComponent } from './components/select-from-tree/select-from-tree.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { DateDisplayPipe } from '../core/pipes/date-display.pipe';
import { SplideComponent } from './components/splide/splide.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TextareaSummernoteComponent } from './components/textarea-summernote/textarea-summernote.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component'; 
import { DirectivesModule } from '../core/directives/directives.module';
import { createTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';


@Directive({
  selector: "[color]"
})
export class ColorDirective {
  @Input('color')
  color!: string;
}
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    SharedComponent,
    NavbarComponent,
    FooterComponent,
    ButtonComponent,
    SnackBarComponent,
    WarningSnackBarComponent,
    SuccessSnackBarComponent,
    ErrorSnackBarComponent,
    UserProfileIconComponent,
    TreeComponent,
    HeaderComponent,
    StarRatingComponent,
    ColorDirective,
    // FiltrationComponent,
    ActionsPopupComponent,
    RequestOfferPopupComponent,
    RequestStatusComponent,
    FreelancerOfferComponent,
    EditOfferComponent,
    ChatComponent,
    LoaderProgressComponent,
    PlaceHolderComponent,
    ForgetPasswordComponent,
    ForgetPasswordMobileComponent,
    ForgetPasswordOtpComponent,
    ForgetPasswordNewPasswordComponent,
    NotificationComponent,
    NotificationSnackBarComponent,
    ChatDatePipe,
    RequestFilterationComponent,
    MapComponent,
    MobileInputComponent,
    SelectFromTreeComponent,
    DateDisplayPipe,
    SplideComponent,
    TextareaSummernoteComponent,
    SkeletonComponent,
  ],
  imports: [
    MatProgressSpinnerModule,
    RouterModule,
    NgOtpInputModule,
    NgxSummernoteModule,
    CommonModule,
    TranslateModule.forRoot({ 
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatSlideToggleModule,
    FormsModule,
    MatSnackBarModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    MatTreeModule,
    MatIconModule,
    StarRatingModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatAutocompleteModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    MatListModule,
    MatDividerModule,
    DirectivesModule,
    AngularFireStorageModule,
    LottieModule.forRoot({ player: playerFactory }),
    IntlInputPhoneModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,
      language: 'ar',
      libraries: ['places', 'geometry']
    }),
    GoogleMapsModule,
  ],
  exports: [
    IntlInputPhoneModule,
    LottieModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    NavbarComponent,
    FooterComponent,
    ButtonComponent,
    SnackBarComponent,
    WarningSnackBarComponent,
    SuccessSnackBarComponent,
    ErrorSnackBarComponent,
    UserProfileIconComponent,
    TreeComponent,
    HeaderComponent,
    StarRatingComponent,
    // FiltrationComponent,
    ActionsPopupComponent,
    RequestOfferPopupComponent,
    RequestStatusComponent,
    FreelancerOfferComponent,
    EditOfferComponent,
    ChatComponent,
    LoaderProgressComponent,
    PlaceHolderComponent,
    ForgetPasswordComponent,
    ForgetPasswordMobileComponent,
    ForgetPasswordOtpComponent,
    ForgetPasswordNewPasswordComponent,
    NotificationComponent,
    NotificationSnackBarComponent,
    RequestFilterationComponent,
    MapComponent,
    MobileInputComponent,
    SelectFromTreeComponent,
    ChatDatePipe,
    DateDisplayPipe,
    SplideComponent,
    TextareaSummernoteComponent,
    SkeletonComponent
  ],
  providers:[AsyncPipe , DatePipe,DateDisplayPipe,MatSnackBarModule],

})
export class SharedModule { }
