import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
declare var $: any;
@Component({
  selector: 'app-user-profile-icon',
  templateUrl: './user-profile-icon.component.html',
  styleUrls: ['./user-profile-icon.component.scss']
})
export class UserProfileIconComponent {
  available:boolean = true
  userData:any;
  constructor(public _AuthService: AuthService, private profile: ProfileService , public activeRouter: Router){}
  ngOnInit(): void {
    this._AuthService.getUserName()
    this._AuthService.getUserFullName()
    this._AuthService.getUserId()
    this._AuthService.getUserRole()
    this._AuthService.getUserImage()
    $(document).ready(function () {
      $('#dropDown').click(function () {
        $('.drop-down').toggleClass('drop-down--active');
      });
    });
    this.profile.getUserData(this._AuthService.id).subscribe((res: any) => {
      this.userData = res.data;
      this.profile.Avatar.next(res.data?.avatar)
      if (res.data.available == 1){
        this.available = false
      }else {
          this.available = true
        }
      })
  }
  closeDropdown() {
    this._AuthService.displayDropdown = !this._AuthService.displayDropdown
  }
  
  changeProfile(id:any){
    if(this.userData.role.id == 2){
      this.activeRouter.navigate(['profile/UserProfile/my-userRequests'])
      if(this.activeRouter.url.includes('/profile')){
        setTimeout(() => {
          window.location.reload()
        }, 100);
      }
    }else {
      this.activeRouter.navigate(['profile/FreelancerProfile/my-profile'])
      if(this.activeRouter.url.includes('/profile')){
        setTimeout(() => {
          window.location.reload()
        }, 100);
      }
    }
  }
  switch(){
    if (this.available == false) {
      this.profile.updateUserData(this.userData.id, {available: 1}).subscribe((res: any) => {
      })
    } else {
      this.profile.updateUserData(this.userData.id, {available: 0}).subscribe((res: any) => {
      })
    }
  }

  public get userAvatar() {
    return this.profile.Avatar.getValue();
  }
}
