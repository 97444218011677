import { Component, Input } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { ToasterService } from "../../services/toaster.service";
declare var $: any;

@Component({
  selector: "app-card-of-profile-side",
  templateUrl: "./card-of-profile-side.component.html",
  styleUrls: ["./card-of-profile-side.component.scss"],
})
export class CardOfProfileSideComponent {
  @Input() data: any;
  @Input() editData: any = [];
  @Input() addButton: string = "";
  @Input() Title: string = "";
  @Input() supTitle: string = "";
  @Input() social: any = [];
  @Input() id: string = "";
  @Input() userId: string = "";
  editArray: any;
  number: number = 0;
  show: boolean = true;
  box: number[] = [1, 2, 3, 4];
  level_id: any[] = [];

  constructor(
    private profile: ProfileService,
    public _AuthService: AuthService,
    public _toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.getData();
  }
  ngOnChanges() { 
  }
  edit() {
    $(`#${this.id}`).modal("show");
  }
  submit(value:any) {
    if(this.id == 'language'){
      value = []
      this.editData.forEach((element: any, index: number) => {
        value.push({ skill:element.$ngOptionLabel , type: this.id, level_id: this.level_id[index] })
      })
    }else {
      value.social = this.social
    }
    this.profile
      .updateUserData(this._AuthService.id, value)
      .subscribe((res: any) => {
        if (res?.status == 200) {
          window.location.reload();
        }
        else {
         this._toasterService.showError("يجب ادخال المهارات او اللغة");
        }
      })
  }
  rating(rate: any, index: any) {
    this.level_id[index] = rate;
  }
  remove(i: number) {
    if(this.id == 'language'){
    this.editData.splice(i, 1);
    this.level_id?.splice(i, 1);
  }else {
    this.editData.splice(i, 1);
    this.social.splice(i, 1)
  }
  }
  New() {
    if(this.id == 'language'){
    this.editData.push("");
    this.level_id?.push("");
  }else {
    this.editData.push("");
    this.social.push({ value: '' })
  }
  }
  getData() {
    this.profile.getLangAndSocial(this.id).subscribe((res: any) => {
      this.editArray = res.data;
    });
  }
  socialValue(event: any, i: number) {
    this.social[i].value = event.target.value
    // this.validNumber = i
  }
}
