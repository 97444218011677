import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';

@Component({
  selector: 'app-users-card',
  templateUrl: './users-card.component.html',
  styleUrls: ['./users-card.component.scss']
})
export class UsersCardComponent {
 @Input() data: any
 @Output() Favorite = new EventEmitter()
 number:number = 5
  seeMore: boolean = true
  numberOfSee: number = 180;
  constructor(public _BreakPointService: BreakPointService , public _AuthService: AuthService) {
     this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }
  ngOnChanges(){
  }
  addOrRemoveRequestToFavorite(id: any, i: number ,event:any) {
    this.Favorite.emit({id:id , i:i , event:event});
    }
}
