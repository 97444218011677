<div
  class="bg-white mt-3 d-flex flex-column justify-content-center border-f-radius-s12 p-4"
  *ngIf="id == 'language'"
>
  <p
    class="m-0 mt-3 w-100 text-support2-color font-Bold-s16 d-flex justify-content-between align-items-center line position-relative pb-2"
  >
    <span>{{ Title }}</span>
    <span 
    *ngIf="this._AuthService.id == userId"
      class="cursor-click Border-grey border-f-radius-s12 bg-light-white p-2.5 d-flex justify-content-center align-items-center"
      (click)="edit()"
    >
      <i
        class="ic-profile-pencil iC-S20 img-Background-Cover d-flex relative"
      ></i>
    </span>
  </p>
  <div class="d-flex align-items-center mt-3 overflow-hidden flex-wrap">
    <div
      *ngFor="let items of data?.slice(0, number); let i = index"
      class="mt-3 w-100"
    >
      <div class="m-0 d-flex justify-between w-100">
        <span class="font-Regular-s16 line-height-2 text-dark-color">{{
          items?.skill
        }}</span>
        <div>
          <div class="star-rating">
            <span
              class="ms-2 me-0 lvl"
              *ngFor="let box of box"
              [class.full]="box <= items?.level?.level"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p
        class="mt-4 cursor-click font-Regular-s16 text-dark-color"
        *ngIf="data?.language?.length > 3 && show"
        (click)="number = data?.language?.length; show = false"
      >
        أظهر المزيد
      </p>
      <p
        class="mt-4 cursor-click font-Regular-s16 text-dark-color"
        *ngIf="data?.language?.length > 3 && !show"
        (click)="number = 3; show = true"
      >
        أظهر أقل
      </p>
    </div>
    <div 
      *ngIf="data?.length == 0"
      class="d-flex flex-column justify-content-center align-items-center w-100"
    >
      <!-- <i
        class="ic-profile-empty-languages first-letter:d-flex img-Background-Contain iC-S94"
      ></i> -->
      <p class="m-0 font-Bold-s16 text-dark-color">لا توجد {{ supTitle }}</p>
      <p class="m-0 font-Regular-s14 text-dark-color text-center">
        لم يتم اضافه {{ supTitle }} بعد
      </p>
      <button 
      *ngIf="this._AuthService.id == userId"
        id="add"
        aria-label="add"
        (click)="edit()"
        class="border-0 w-100 d-flex justify-center py-2 font-Bold-s16 text-white mt-4 bg-main border-f-radius-s12"
      >
        {{ addButton }}
      </button>
    </div>
  </div>
</div>
<div
  class="bg-white mt-3 d-flex flex-column justify-content-center border-f-radius-s12 p-4"
  *ngIf="id == 'social'"
>
  <p
    class="m-0 mt-3 w-100 text-dark-color font-Bold-s16 d-flex justify-content-between align-items-center line position-relative pb-2"
  >
    <span>مواقع شغلي الآخرى</span>
    <span
    *ngIf="this._AuthService.id == userId"
      class="cursor-click Border-grey border-f-radius-s12 bg-light-white p-2.5 d-flex justify-content-center align-items-center"
      (click)="edit()"
    >
      <i
        class="ic-profile-pencil iC-S20 img-Background-Cover d-flex relative"
      ></i>
    </span>
  </p>
  <div class="d-flex align-items-center mt-3 overflow-hidden gap-3 flex-wrap">
    <a
      [href]="items.value"
      aria-label="social"
      target="_blank"
      *ngFor="let items of data"
      class="mt-4 ms-0 p-1.5 border-f-radius-s12 bg-white-sup"
    >
      <img
        class="iC-S40"
        [src]="items.logo"
        loading="lazy"
        alt="nationality-logo"
      />
    </a>
  </div>
</div>
<!-- Modal edit -->
<div
  class="modal fade"
  [id]="id"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content border-0 border-f-radius-s12">
      <!-- Modal content -->
      <div class="modal-body p-md-5 mx-4">
        <!-- Header -->
        <div class="d-flex flex-column align-items-center mt-4">
          <p class="m-0 font-Bold-s20 text-dark-color mb-3">
            أضف {{ supTitle }}
          </p>
          <p class="m-0 font-Regular-s14 text-light-black-color text-center">
            للتأكد من أن ملفك الشخصي كامل ويمثلك بدقة على منصتنا المستقلة، يرجى
            ملء نموذج معلومات المستخدم أدناه. ستساعدنا المعلومات التي تقدمها على
            توصيلك بالفرص ذات الصلة وخلق تجربة شخصية لك
          </p>
        </div>
        <form
          class="bg-white p-md-5 mt-4 border-b-radius-s12"
          #addOrEdit="ngForm"
          (ngSubmit)="submit(addOrEdit.value)"
        >
          <div class="" *ngIf="id == 'language'">
            <!-- add language -->
            <div
              class="row mb-4 align-items-center"
              *ngFor="let item of editData; let i = index"
            >
              <div class="col-md-5">
                <label class="font-Bold-s14 text-dark-color mb-2" for="">{{
                  supTitle
                }}</label>
                <ng-select
                  [searchable]="true"
                  class="w-100 Border-purple"
                  [name]="id"
                  required
                  [(ngModel)]="editData[i]"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <!-- <ng-option selected disabled hidden>لغة</ng-option> -->
                  <!-- <mat-label>لغة</mat-label> -->
                  <ng-option
                    [value]="editData?.name"
                    *ngFor="let item of editArray"
                    >{{ item?.name }}</ng-option
                  >
                </ng-select>
              </div>
              <div
                class="col-md-5 ps-0 pe-4 mt-md-0 mt-3 d-md-block d-flex align-items-center justify-content-between"
              >
                <label class="font-Bold-s14 d-flex text-dark-color" for=""
                  >الكفاءة</label
                >
                <div class="star-rating mt-md-3 pt-md-1">
                  <span
                    class="ms-2 me-0 lvl"
                    *ngFor="let box of box"
                    [class.full]="box <= level_id[i]"
                    (click)="rating(box, i)"
                  ></span>
                </div>
                <div
                  class="d-md-none d-flex align-items-center col-2 mt-md-5 cursor-click"
                  (click)="remove(i)"
                >
                  <p
                    class="border-f-radius-s12 font-Regular-s12 text-red-color m-0 ms-1 me-0"
                  >
                    حذف
                  </p>
                  <i
                    class="ic-profile-delete d-flex img-Background-none iC-S16"
                  ></i>
                </div>
              </div>
              <div
                class="d-md-flex d-none align-items-center col-2 mt-md-5 cursor-click"
                (click)="remove(i)"
              >
                <p
                  class="border-f-radius-s12 font-Regular-s12 text-red-color m-0 ms-1 me-0"
                >
                  حذف
                </p>
                <i
                  class="ic-profile-delete d-flex img-Background-none iC-S16"
                ></i>
              </div>
            </div>
            <!-- add one more -->
            <div
              class="border-f-radius-s12 Border-dashed py-2.5 d-flex justify-content-center align-items-center w-100 cursor-click"
              (click)="New()"
            >
              <i
                class="ic-profile-plus d-flex iC-S20 ms-2 me-0 img-Background-Cover"
              ></i>
              <p class="m-0 font-Bold-s14 text-dark-color">
                {{ addButton }} أخري
              </p>
            </div>
          </div>
          <div class="" *ngIf="id == 'social'">
            <!-- add web sites -->
            <div class="mb-4" *ngFor="let item of social; let i = index">
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 font-Bold-s14 text-dark-color">لينك</p>
                <div
                  class="d-flex align-items-center mb-1 cursor-click"
                  (click)="remove(i)"
                >
                  <p class="border-f-radius-s12 text-red-color m-0 ms-1 me-0">
                    حذف
                  </p>
                  <i
                    class="ic-profile-delete d-flex img-Background-none iC-S16"
                  ></i>
                </div>
              </div>
              <div
                class="d-flex justify-content-center mt-2.5 p-1 border-f-radius-s12 Border-light-grey"
              >
                <ng-select
                  [searchable]="true"
                  class="w-1/3 border-r-radius-s12"
                  style="height: 40px !important"
                  name="social_id"
                  placeholder="لينك"
                  required
                  [(ngModel)]="social[i].social_id"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <!-- <ng-option selected disabled hidden>الجنسية</ng-option> -->
                  <ng-option
                    [value]="social.id"
                    *ngFor="let social of editArray"
                    >{{ social?.name }}</ng-option
                  >
                </ng-select>
                <input
                  placeholder="اكتب لينك موقع"
                  type="text"
                  [value]="social[i].value"
                  (keyup)="socialValue($event, i)"
                  name="social"
                  aria-label="social"
                  required
                  class="w-100 border-f-radius-s12 px-3 py-2 text-support2-color"
                />
              </div>
            </div>
            <!-- add one more -->
            <div
              class="border-f-radius-s12 Border-dashed py-2.5 d-flex justify-content-center align-items-center w-100 cursor-click"
              (click)="New()"
            >
              <i
                class="ic-profile-plus d-flex iC-S20 ms-2 me-0 img-Background-Cover"
              ></i>
              <p class="m-0 font-Bold-s14 text-dark-color">
                إضف {{ supTitle }}
              </p>
            </div>
          </div>
          <div class="w-100">
            <button
              type="submit"
              id="submit"
              aria-label="submit"
              class="border-0 w-100 d-flex justify-center py-2 font-Bold-s16 text-white mt-4 bg-main border-f-radius-s12"
            >
              تعديل
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
