import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreakPointService } from 'src/app/core/services/break-point.service';

@Component({
  selector: 'app-tags-search-profile',
  templateUrl: './tags-search-profile.component.html',
  styleUrls: ['./tags-search-profile.component.scss']
})
export class TagsSearchProfileComponent {
  @Input() data:any 
  @Output() value = new EventEmitter
  @Input() nav: any = []
  constructor(public _BreakPointService: BreakPointService) {}
  ngOnInit(): void {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }
  search(event:any){
    this.value.emit(event.target.value)
  }
}
