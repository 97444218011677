<div class="d-flex flex-column gap-4">
  <div
    [routerLink]="'/request/request-details/' + data.id"
    class="border-f-radius-s12 Border-f-purple-25 p-4 box-shadow bg-white d-flex gap-3 cursor-click"
    *ngFor="let data of data?.slice(0, number); let i = index"
  >
    <div class="d-flex flex-column gap-2 w-100">
      <div
        class="d-flex align-items-center gap-3"
        *ngIf="activeRouter.url.includes('my-special-request')"
      >
        <img
          [src]="data?.user?.avatar"
          alt=""
          class="iC-S62 img-Background-Cover border-f-radius-s8"
        />
        <p class="m-0 text-support2-color font-Bold-s18 d-flex flex-column gap-2">
          {{ data?.name }}
          <span class="font-Regular-s14 text-support3-color">
            <p
              class="m-0 d-flex align-items-center gap-3 font-Regular-s14 text-support3-color"
            >
              <span class="d-flex align-items-center gap-2">
                <i
                  class="d-flex ic-profile-location ic-location-Size img-Background-Cover"
                ></i>
                {{ data?.type_work | translate }}
              </span>
              <i class="d-flex ic-donte-profile iC-S8 img-Background-Cover"></i>
              <span class="d-flex align-items-center gap-2">
                <i class="d-flex ic-time-profile iC-S14 img-Background-Cover"></i>
                نشر منذ {{ data?.created_at_value }}
              </span>
              <i class="d-flex ic-donte-profile iC-S8 img-Background-Cover"></i>
              <span class="d-flex align-items-center gap-2">
                <i
                  class="d-flex ic-User-Size img-Background-Cover ic-profile-money"
                >
                </i>
                {{ data?.payment_method | translate}}
              </span>
            </p>
          </span>
        </p>
      </div>
      <div
        class="d-flex align-items-center gap-3"
        *ngIf="activeRouter.url.includes('my-offer-request')"
      >
        <img
          [src]="data?.user?.avatar"
          alt=""
          class="iC-S62 img-Background-Cover border-f-radius-s8"
        />
        <p class="m-0 text-support2-color font-Bold-s18 d-flex flex-column gap-2">
          {{ data?.user?.fullname }}
          <span class="font-Regular-s14 text-support3-color">
            {{ data?.user?.job_name }}
          </span>
        </p>
      </div>
      <p
        *ngIf="!activeRouter.url.includes('my-special-request')"
        class="m-0 text-support2-color font-Bold-s18 d-flex align-items-center gap-3"
      >
        <span>{{ data?.name }}</span>
        <span class="font-Medium-s10 py-1 px-2 bg-black border-f-radius-s8">{{
          data?.status?.name
        }}</span>
      </p>
      <p
        *ngIf="!activeRouter.url.includes('my-special-request')"
        class="m-0 d-flex align-items-center gap-3 font-Regular-s14 text-support3-color"
      >
        <span class="d-flex align-items-center gap-2">
          <i
            class="d-flex ic-profile-location ic-location-Size img-Background-Cover"
          ></i>
          {{ data?.type_work | translate }}
        </span>
        <i class="d-flex ic-donte-profile iC-S8 img-Background-Cover"></i>
        <span class="d-flex align-items-center gap-2">
          <i class="d-flex ic-time-profile iC-S14 img-Background-Cover"></i>
          نشر منذ {{ data?.created_at_value }}
        </span>
        <i
          *ngIf="!activeRouter.url.includes('my-saved')"
          class="d-flex ic-donte-profile iC-S8 img-Background-Cover"
        ></i>
        <span
          class="d-flex align-items-center gap-2"
          *ngIf="!activeRouter.url.includes('my-saved')"
        >
          <i
            class="d-flex ic-User-Size img-Background-Cover"
            [ngClass]="
              activeRouter.url.includes('my-special-request')
                ? 'ic-profile-money'
                : 'ic-user-profile'
            "
          >
          </i>
          {{
            activeRouter.url.includes("my-special-request")
              ? ""
              : data?.offerCount + " متقدمين على الطلب"
          }}
        </span>
      </p>
      <p
        class="m-0 font-Regular-s14 text-support3-color d-flex flex-column gap-2 text-break"
      >
        <span
          *ngIf="!activeRouter.url.includes('my-saved')"
          class="text-support2-color font-Bold-s15"
          >{{
            activeRouter.url.includes("my-special-request") ? "" : "وصف المشروع :"
          }}</span
        >
        {{ data?.description }}
      </p>
      <div
        *ngIf="
          data?.category?.length !== 0 "
        class="m-0 font-Regular-s14 text-support3-color d-flex gap-2 flex-column text-break"
      >
        <p
          *ngIf="!activeRouter.url.includes('my-saved')
          && !activeRouter.url.includes('my-special-request')"
          class="m-0 text-support2-color font-Bold-s15"
        >
          المهارات المطلوبة :
        </p>
        <p class="m-0 d-flex align-items-center">
          <span
            *ngFor="let items of data?.category?.slice(0, 4)"
            class="m-0 text-nowrap ms-2 bg-light-white text-support2-color font-Regular-s12 px-3 py-1 border-f-radius-s10"
          >
            {{ items?.name }}</span
          >
          <span
            *ngIf="data?.category?.length >= 4"
            class="m-0 ms-2 bg-light-white font-Regular-s12 px-3 py-1 border-f-radius-s10 cursor-click"
            >+4</span
          >
        </p>
      </div>
    </div>
    <div
      class="Border-grey border-f-radius-s12 bg-light-white p-2.5"
      style="height: 41.5px"
    >
      <i
        [ngClass]="
          data?.is_favourite == 0 ? 'ic-all-request-save' : 'ic-request-favorite'
        "
        *ngIf="!data.task_owner"
        class="cursor-click d-flex img-Background-Cover iC-S16X20"
        (click)="addOrRemoveRequestToFavorite(data?.id, i, $event)"
      ></i>
      <i
        *ngIf="data.task_owner"
        class="cursor-click ic-profile-pencil d-flex img-Background-Cover iC-S20"
        [routerLink]="'/request/edit-request/' + data.id"
      ></i>
    </div>
  </div>
</div>
<div
  class="d-flex justify-content-center w-100 mt-2"
  *ngIf="number < data?.length"
>
  <button
    class="border-0 border-f-radius-s8 font-Bold-s16 text-white-color bg-main-gredient px-5 py-1 line-height-2"
    (click)="number = data?.length"
  >
    إعرض المزيد
  </button>
</div>
