<div class="border-f-radius-s12 mb-4 Border-f-purple-25 p-4 box-shadow bg-white d-flex gap-3 cursor-click"
  *ngFor="let data of data?.slice(0 ,number); let i = index"
>
<!--img card-->
<div id="{{'s' + data.id}}" class="carousel position-relative slide flex-column d-md-flex align-items-center justify-content-center" *ngIf="data?.document?.length > 0">
  <div class="carousel-inner overflow-visible">
    <div class="carousel-item h-100" *ngFor="let images of data?.document; let numI = index" 
      [ngClass]="{'active': numI === 0}" data-bs-interval="2000">
      <i [style.backgroundImage]="'url(' + images.file + ')'" 
         [id]="data.id" 
         class="d-flex img-Background-Cover h-100 image-dimenstion border-f-radius-s12">
      </i>
      <p class="text-support2-color text-center m-0 font-Bold-s16 relative z-10">
        {{data?.document.length}} / {{numI+1}} 
      </p>
    </div>
  </div>
</div>
<i *ngIf="data.document.length == 0" class="ic-placeoverOfAdsImage-profile img-Background-Cover carousel-inner border-f-radius-s12" ></i>
<div class="d-flex flex-column gap-2 w-100" [routerLink]="'/ads/ads-details/'+ data?.id">
    <p class="m-0 text-support2-color font-Bold-s18">{{ data?.name }}</p>
    <p class="m-0 d-flex align-items-center gap-3 font-Regular-s14 text-support3-color">
      <span class="d-flex align-items-center gap-2">
          <i class="d-flex  ic-User-Size ic-profile-money img-Background-Cover"></i>
          {{data?.price}}
          ريال سعودى
      </span>
      <i class="d-flex ic-donte-profile iC-S8 img-Background-Cover"></i>
      <span class="d-flex align-items-center gap-2">
        <i class="d-flex ic-time-profile iC-S14 img-Background-Cover"></i>
        نشر منذ {{ data?.created_at_value }}
      </span>
        <i class="d-flex ic-donte-profile iC-S8 img-Background-Cover"></i>
        <span class="d-flex align-items-center gap-2">
        <i class="d-flex  ic-watch-Size ic-watch-profile img-Background-Cover"></i>
        {{data?.view}}
        مشاهدة
      </span>
    </p>
    <p
      class="m-0 mt-1 font-Regular-s14 text-support3-color text-break">
      {{ data?.description }}
    </p>
  </div>
  <div class="Border-grey border-f-radius-s12 bg-light-white p-2.5" style="height: 41.5px;">
    <i [ngClass]=" data?.is_favourite == 0 ? 'ic-all-request-save' :'ic-request-favorite'"
    *ngIf="data.user.id !== auth.id"
    class="cursor-click d-flex img-Background-Cover iC-S16X20"
      (click)="addOrRemoveRequestToFavorite(data?.id ,i ,$event)"></i>
      <i  *ngIf="data.user.id == auth.id"
      class="cursor-click ic-profile-pencil d-flex img-Background-Cover iC-S20" [routerLink]="'/ads/edit-ads/'+ data.id"></i>
  </div> 
</div>
<div class="d-flex justify-content-center w-100 mt-2" *ngIf="number < data?.length">
  <button class="border-0 border-f-radius-s8 font-Bold-s16 text-white-color bg-main-gredient px-5 py-1 line-height-2"
  (click)="number = data?.length"
  >إعرض المزيد</button>
</div>
