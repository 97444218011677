<div class="d-lg-flex justify-content-between align-items-center w-100"
  *ngIf="this._BreakPointService.isMobile === false">
  <div class="my-4 gap-10 d-flex align-items-center w-100">
    <p *ngFor="let item of nav ;let  i = index" class="m-0 font-Regular-s14 text-support2-color cursor-click relative" routerLink="{{item.routerLink}}"
    routerLinkActive="active">{{item.name}}</p>
  </div>
  <div class="d-flex justify-content-end w-100">
    <div class="d-flex py-0.5 justify-content-center~ border-f-radius-s15 Border-light-grey bg-light-white align-items-center w-75">
      <div class="mx-2">
        <i class="ic-all-request-Vector iC-S20 z-10 d-flex cursor-click img-Background-Cover"></i>
      </div>
      <input class="font-Regular-s16 py-2.5 w-100 mx-2 bg-light-white text-support2-color
      border-f-radius-s15" placeholder="أبحث عن ..." (keyup)="search($event)" aria-label='search'>
      <button class="border-0 border-f-radius-s12 font-Bold-s16 text-white-color z-10 bg-main-gredient mx-2 px-4 py-2" style="z-index: 1;"
      id="search" aria-label="search">
        بحث
      </button>
    </div>
  </div>
  </div>
  <div
  class="d-lg-flex  justify-content-between align-items-center w-100"
  *ngIf="this._BreakPointService.isMobile === true"
>
  <div class="d-flex justify-content-end w-100">
    <div
      class="d-flex py-0.5 justify-content-center~ border-f-radius-s15 Border-light-grey bg-light-white align-items-center w-100"
    >
      <i
        class="ic-all-request-Vector iC-S20 z-10 px-2 mx-2 d-flex cursor-click img-Background-Cover"
      ></i>
      <input
        class="font-Regular-s16 py-2.5 w-100 mx-2 bg-light-white text-support2-color border-f-radius-s15"
        placeholder="أبحث عن ..."
        (keyup)="search($event)"
        aria-label="search"
      />
      <button
        class="border-0 border-f-radius-s12 font-Bold-s16 text-white-color z-10 bg-main mx-2 px-4 py-2"
        style="z-index: 1"
        id="search"
        aria-label="search"
      >
        بحث
      </button>
    </div>
  </div>
  <div class="my-4 gap-10 d-flex align-items-center w-100">
    <p
      *ngFor="let item of nav; let i = index"
      class="m-0 font-Regular-s14 text-support3-color cursor-click relative"
      routerLink="{{ item.routerLink }}"
      [ngClass]="{ 'd-none': i !== 0 && data?.role?.id == 2 }"
      routerLinkActive="active"
      [state]="item?.state"
    >
      {{ item.name }}
    </p>
  </div>
</div>