<!-- [ngStyle]="{'transform': isNavbarVisible ? 'translateY(0)' : 'translateY(-100%)'}" -->
<nav class="navbar navbar-expand-lg bg-white border-B-radius-s20 py-3 z-50 shadow" *ngIf='!this.activeRouter.url.includes("/request/edit-request/")
  && !this.activeRouter.url.includes("/request/Add-new-request")
  && !this.activeRouter.url.includes("/register")
  && !this.activeRouter.url.includes("/login")
  && !this.activeRouter.url.includes("Occupy-me")
  && !this.activeRouter.url.includes("/ads/add-new-advrties")
  && !this.activeRouter.url.includes("/ads/edit-ads")
  && !this.activeRouter.url.includes("/forget-password")'>
  <div class="container px-md-0 px-3">
    <!-- Navbar Logo -->
    <button class="navbar-brand ms-5" routerLink="/" id="logo" aria-label="logo">
      <img class="hidden logo-holder d-block" src="../../../../assets/Icons/main-logo.webp" alt="Your Company logo" loading="lazy" >
    </button>

    <div class="gap-8 d-md-none d-flex align-items-center" *ngIf="_AuthService.userValue?.status">
      <div class="position-relative" (click)="notification()">
        <!--  -->
        <i class="iC-S24 img-Background-Contain d-flex ic-nav-notification cursor-click z-50 relative"></i>
        <!-- (mouseenter)="notification()" (mouseleave)="closeNotification()" -->
        <i *ngIf="calcNotification !== 0" class="iC-S16 img-Background-Contain d-flex ic-nav-messages-dot position-absolute -left-1 z-50 -bottom-1
        justify-center align-items-start" (click)="notification()">
        <span class="font-Regular-s10">{{calcNotification}}</span>
        </i>
        <div class="notification-component top-14" [ngClass]="{'notification-icon': open === false }">
          <app-notification [open]="open"
          (click)="notification()" [data]="reqNotifications"
          ></app-notification>
        </div>
      </div>
      <!-- <span class="position-relative" style="color: #657FBF;font-size: 20px;" *ngIf="counter>0"><i class="fa-regular fa-message"></i> <span class="position-absolute messages-counter">{{counter}} </span></span> -->
      <i class="iC-S24 img-Background-Contain d-flex ic-nav-messages cursor-click position-relative" routerLink="/chat"><span class="position-absolute messages-counter" *ngIf="counter>0">{{counter}} </span></i>
    </div>
    <!-- Responsive Button -->
    <button id="responsive" class="navbar-toggler bg-main border-0" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="ic-bars-staggered iC-S20 img-Background-Contain d-flex"></i>
    </button>

    <!-- Navbar List -->
    <div class="collapse navbar-collapse justify-content-between me-md-3 mt-lg-0 mt-3" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0  gap-lg-5 gap-3">
        <!-- Navbar elements -->
        <li class="nav-item d-none d-lg-block">
          <!-- Home -->
          <button id="home" aria-label="home"
          class="nav-link active font-Regular-s16 cursor-click d-flex align-items-center gap-2" aria-current="page"
            routerLink="/home" [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="router-link-class font-Bold-s16 ">
            <i class="ic-Home-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0  text-light-black-color">الرئيسية</p>
          </button>
        </li>
        <li class="nav-item d-lg-none d-block" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">
          <!-- Home -->
          <button id="homeResp" aria-label="homeResp"
          class="nav-link active font-Regular-s16 cursor-click d-flex align-items-center gap-2" aria-current="page"
            routerLink="/home" [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="router-link-class font-Bold-s16">
            <i class="ic-Home-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0  text-light-black-color">الرئيسية</p>
          </button>
        </li>
        <li class="nav-item  font-Regular-s16 d-none d-lg-block" routerLink="/ads"
          routerLinkActive="router-link-class font-Bold-s16">
          <button id="Platform" aria-label="Platform"
          class="nav-link cursor-click d-flex align-items-center gap-2">
            <i class="ic-Browse-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0  text-light-black-color">تصفح المنصة</p>
          </button>
        </li>
        <!-- responsive -->
        <li class="nav-item  font-Regular-s16 d-lg-none d-block" routerLink="/ads"
          data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          routerLinkActive="router-link-class font-Bold-s16">
          <button  id="PlatformResp" aria-label="PlatformResp"
          class="nav-link cursor-click d-flex align-items-center gap-2">
            <i class="ic-Browse-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0 text-light-black-color">تصفح المنصة</p>
          </button>
        </li>
        <li class="nav-item d-lg-none d-block" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          routerLink="/ads/add-new-advrties" *ngIf="this.userData?.role?.id != 2 && _AuthService.userValue?.status">
          <button id="addAds" aria-label="addAds"
          class="nav-link cursor-click d-flex align-items-center gap-2">
            <i class="ic-Add-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0 font-Regular-s16 text-light-black-color">أضف إعلان</p>
          </button>
        </li>
        <li class="nav-item d-lg-none d-block" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          [routerLink]="['/profile/FreelancerProfile/my-profile']" *ngIf="_AuthService.userValue?.status">
          <button id="profile" aria-label="profile"
          class="nav-link cursor-click d-flex align-items-center gap-2">
            <i class="ic-User-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0 font-Regular-s16 text-light-black-color">الملف الشخصي</p>
          </button>
        </li>

        <li class="nav-item d-lg-none d-block" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          *ngIf="_AuthService.userValue?.status">
          <button id="sitting" aria-label="sitting"
          class="nav-link cursor-click d-flex align-items-center gap-2" routerLink="/settings">
            <i class="ic-settings iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0 font-Regular-s16 text-light-black-color">الإعدادات</p>
          </button>
        </li>
        <li class="nav-item d-lg-none d-block" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          *ngIf="_AuthService.userValue?.status" (click)="this._AuthService.logout();this._AuthService.closeDropdown()">
          <button id="logut" aria-label="logut"
          class="nav-link cursor-click d-flex align-items-center gap-2">
            <i class="ic-Signout-icon iC-S20 img-Background-Contain d-flex"></i>
            <p class="m-0 font-Regular-s16 text-light-black-color">تسجيل خروج</p>
          </button>
        </li>
      </ul>
      <div
        class="d-flex flex-lg-row flex-column align-items-lg-center mt-lg-0 mt-3 {{_AuthService.userValue?.status? 'gap-lg-4 gap-3' :'gap-4'}}">
        <div class="gap-8 d-md-flex d-none align-items-center" *ngIf="_AuthService.userValue?.status">
          <div class="position-relative" (click)="notification()">
            <!--  -->
            <i class="iC-S24 img-Background-Contain d-flex ic-nav-notification cursor-click z-50 relative"></i>
            <!-- (mouseenter)="notification()" (mouseleave)="closeNotification()" -->
            <i *ngIf="calcNotification !== 0" class="iC-S16 img-Background-Contain d-flex ic-nav-messages-dot position-absolute -left-1 z-50 -bottom-1
            justify-center align-items-start" (click)="notification()">
            <span class="font-Regular-s10">{{calcNotification}}</span>
            </i>
            <div class="notification-component top-14" [ngClass]="{'notification-icon': open === false }">
              <app-notification [open]="open"
              (click)="notification()" [data]="reqNotifications"
              ></app-notification>
            </div>
          </div>
          <!-- <span class="position-relative" style="color: #657FBF;font-size: 20px;" *ngIf="counter>0"><i class="fa-regular fa-message"></i> <span class="position-absolute messages-counter">{{counter}} </span></span> -->
          <i class="iC-S24 img-Background-Contain d-md-flex d-none ic-nav-messages cursor-click position-relative" routerLink="/chat"><span class="position-absolute messages-counter" *ngIf="counter>0">{{counter}} </span></i>
        </div>
        <!-- Request Button -->
        <div data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"
          class="position-relative d-lg-none d-block" *ngIf="_AuthService.userValue?.status">
          <button [routerLink]='["/request/Add-new-request"]' id="order" aria-label="order"
            class="bg-main-gredient border-f-radius-s12 d-flex align-items-center gap-2 px-4 py-2 position-relative"
            style="z-index: 2;">
            <i class="ic-Note-icon iC-S20 img-Background-Contain d-flex my-1"></i>
            <p class="m-0 font-Bold-s16 text-white-color">اطلب عرض سعر</p>
            <!-- shadow div -->
            <div class="shadow-holder"></div>
          </button>
        </div>
        <div class="position-relative d-none d-lg-block" *ngIf="_AuthService.userValue?.data?.role_id != 2">
          <button [routerLink]='["/ads/add-new-advrties"]' id="orderResp" aria-label="orderResp"
          class=" border-f-radius-s12 d-flex align-items-center Border-f-purple py-2 px-2.5 position-relative Z-10">
          <p class="m-0 font-Bold-s16 text-main-color">انشر إعلانك</p>
          </button>
        </div>
        <div class="position-relative d-none d-lg-block" *ngIf="_AuthService.userValue?.status">
          <button [routerLink]='["/request/Add-new-request"]' id="orderResp" aria-label="orderResp"
            class="bg-main-gredient border-f-radius-s12 d-flex align-items-center gap-2 px-4 py-2 position-relative z-10">
            <i class="ic-Note-icon iC-S20 img-Background-Contain d-flex my-1"></i>
            <p class="m-0 font-Bold-s16 text-white-color">اطلب عرض سعر</p>
            <!-- shadow div -->
            <div class="shadow-holder"></div>
          </button>
        </div>
        <!-- user placeholder image -->
        <div *ngIf="_AuthService.userValue?.status && this._BreakPointService.isMobile === false">
          <app-user-profile-icon></app-user-profile-icon>
        </div>
        <!-- Register or login -->
        <div class="d-flex align-items-center" *ngIf="!_AuthService.userValue?.status">
          <button id="register" aria-label="register"
          class="py-2 px-3 border-f-radius-s12 bg-main text-white font-Bold-s16" routerLink="/register">كن
            مشتغل</button>
          <button  id="login" aria-label="login" class="py-2 px-3 border-f-radius-s12 bg-transparent text-support2-color font-Regular-s16"
            routerLink="/login">تسجيل
            دخول</button>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- close navbar dropdown -->
<div class="position-fixed bg-transparent  start-0 end-0 top-0 bottom-0" *ngIf="this._AuthService.displayDropdown"
  (click)="this._AuthService.closeDropdown()"></div>
