<div class="row overflow-hidden" style="height: 764px;">
    <div class="col-lg-4 h-100 border-f-radius-s12 bg-white">
        <div class="bg-white h-100 py-4">
            <div class="d-flex justify-content-between align-items-center cursor-click" (click)="openTree()">
                <p class="m-0 d-flex font-Bold-s14 align-items-center">
                    <span class="p-2 ms-2 cursor-click border-f-radius-s6 d-flex Border-light-grey align-items-center justify-content-center bg-main relative"
                  *ngIf="_BreakPointService.isMobile == true" (click)="Category()">
                    <i class="ic-all-request-work iC-S16 d-flex img-Background-Cover"></i>
                    <span class="absolute -bottom-2 px-1 -right-2 border-f-radius-s6 Border-light-grey text-white bg-main">{{this.checklistSelection.selected?.length}}</span>
                  </span>
                  <span class="p-2" *ngIf="_BreakPointService.isMobile == false">
                    <i class="ic-all-request-work iC-S16 ms-3 d-flex img-Background-Cover"></i>
                  </span>
                    الاشغال و المجالات
                </p>
                <mat-icon class="text-dark-color">
                    {{chevron}}
                </mat-icon>
            </div>
            <div [@openClose]="isOpen ? 'open' : 'closed'" class="open-close-container overflow-hidden h-100">
                <div
                    class="bg-light-white d-flex mt-3 border-f-radius-s15 px-2 gap-2 Border-light-grey align-items-center w-100">
                    <i class="ic-all-request-Vector iC-S16 z-10 d-flex cursor-click img-Background-Contain"></i>
                    <input aria-label="search" class="font-Regular-s16 w-100 py-2 bg-light-white
                        text-support2-color border-f-radius-s15" placeholder="أبحث عن ..." matInput autocomplete="off"
                        type="text" id="search" (input)="searchForCategories($event.target)">
                </div>
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node class="d-flex justify-content-between" *matTreeNodeDef="let node" matTreeNodePadding>
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <div>
                            <input mat-icon-button name="category" id="{{node.id}}" class="font-Regular-s16 mx-2"
                                type="checkbox" [checked]="checklistSelection.isSelected(node)"
                                (change)="todoLeafItemSelectionToggle(node)">
                            <label class="text-support2-color font-Bold-s12" for="{{node.id}}">{{node.name}}</label>
                        </div>
                        <button type="button" mat-icon-button id="Toggle" aria-label="Toggle"></button>
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-tree-node class="d-flex justify-content-between" *matTreeNodeDef="let node;when: hasChild"
                        matTreeNodePadding>
                        <div>
                            <input mat-icon-button name="category" id="{{node.id}}" class="font-Regular-s16 mx-2"
                                type="checkbox" [checked]="descendantsAllSelected(node)"
                                [indeterminate]="descendantsPartiallySelected(node)"
                                (change)="todoItemSelectionToggle(node)">
                            <label class="text-support2-color font-Bold-s12 d-inline" for="{{node.id}}">{{node.name}}</label>
                        </div>
                        <button type="button" mat-icon-button matTreeNodeToggle id="Toggle"
                            [attr.aria-label]="'Toggle ' + node.name">
                            <mat-icon
                                class="mat-icon-rtl-mirror  {{treeControl.isExpanded(node) ? 'text-dark-color' : ''}}">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                    </mat-tree-node>
                </mat-tree>
            </div>
        </div>
    </div>
    <div class="col-8 border-f-radius-s12 h-100 py-2 overflow-scroll" *ngIf="_BreakPointService.isMobile == false">
        <div class=" d-flex align-items-start flex-wrap bg-white ">
            <p *ngFor="let items of data" class="mx-1 gap-3 m-0 mt-4 border-f-radius-s6 Border-light-grey
          d-flex align-items-center justify-content-center py-1 px-2 bg-white-sup Regular text-support2-color" >
                <span>{{items.name}}</span>
                <button (click)="remove(items)" aria-label="remove" id="remove">
                    <i class="ic-cross iC-S8 d-flex cursor-click img-Background-Contain"></i>
                </button>
            </p>
        </div>
        <div
        class="{{checklistSelection.selected?.length != 0 ? 'd-none' :'d-flex'}} w-100 bg-white border-f-radius-s12  flex-column align-items-center justify-content-center gap-2">
        <img class="placeholder-dimensions img-Background-Cover" loading="lazy"
        src="../../../../../../assets/Icons/regesteration/categories-placeholder.svg" alt="placeholder">
            <p class="m-0 text-dark-color font-Bold-s20 mt-3">لا توجد مجالات</p>
            <p class="m-0 text-support2-color font-Regular-s16 w-75 text-center">
                شارك خبرتك وإنجازاتك العملية ذات الصلة. اذكر أي مشاريع بارزة أنجزتها، والعملاء السابقين الذين عملت معهم،
                وكيف كان
                لمساهماتك تأثير إيجابي.
            </p>
        </div>
      </div>
    </div>

<div class="modal fade" id="Category" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered CategoryModel">
    <div class="modal-content border-0 border-f-radius-s12">
      <!-- Modal content -->
      <div class="modal-body p-5~ mx-4~">
        <!-- Header -->
        <div class="d-flex justify-end "  data-bs-dismiss="modal" aria-label="Close">
          <i class="ic-cross iC-S12 d-flex cursor-click img-Background-Contain"></i>
        </div>
        <div class="d-flex flex-wrap relative">
          <div class="d-flex flex-wrap ">
            <p *ngFor="let items of data" class="mx-1 font-Regular-s16 gap-3 m-0 mt-2 border-f-radius-s6 Border-light-grey
            d-flex align-items-center justify-content-center py-1 px-2 bg-white-sup text-support2-color" >
              <span>{{items.name}}</span>
              <button (click)="remove(items)" id="remove" aria-label="remove">
                  <i class="ic-cross iC-S8 d-flex cursor-click img-Background-Contain"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
