import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent {
number:number = 5
@Input() data:any[]
@Output() Favorite = new EventEmitter()
constructor(public activeRouter: Router) {}
ngOnChanges(){
}
addOrRemoveRequestToFavorite(id: any, i: number ,event:any) {
this.Favorite.emit({id:id , i:i , event:event});

}

}
